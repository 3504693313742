import _ from 'lodash'
import moment from 'moment'
import firebase from './firebase'
import { useEffect, useState, useContext, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { FirebaseContext } from './context'
import { querySnapToObj } from './misc'
import brands from './brands'

const FIREBASE_DEFAULTS = {
  firebase,
  auth: firebase.auth(),
  db: firebase.firestore(),
  functions: firebase.app().functions('asia-northeast1'),
  storage: firebase.storage(),
}

export const useFirebase = () => {
  const [claims, setClaims] = useState(null)
  const [brand, setBrand] = useState(null)
  const [configPublic, setConfigPublic] = useState(null)
  const [initStatus, setInitStatus] = useReducer(
    (state, data) => _.assign({}, state, data),
    { config: true, user: true }
  )

  const auth = firebase.auth()

  useEffect(() => {
    if (!configPublic) return

    auth.languageCode = 'ja'
    auth.settings.appVerificationDisabledForTesting =
      configPublic.appVerificationDisabledForTesting
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      { size: 'invisible' }
    )

    // setting brands
    const hostname = window.location.hostname
    const mergedBrands = _.merge(brands, configPublic.brands)
    const name = _.findKey(mergedBrands, { hostname }) || 'lobeam' // defaults to 'lobeam'

    setBrand({ ...mergedBrands[name], name })
  }, [auth, configPublic])

  useEffect(() => {
    const authUnsub = auth.onAuthStateChanged(async (user) => {
      if (user) {
        console.log(`sign in as ${user.uid}`)
        const tokenResult = await user.getIdTokenResult(true)
        setClaims(tokenResult.claims)
      } else {
        console.log('signed out')
        setClaims(null)
      }

      setInitStatus({ user: false })
    })

    const configPublicUnsub = FIREBASE_DEFAULTS.db
      .doc('config/public')
      .onSnapshot((doc) => {
        setConfigPublic(doc.data())
        setInitStatus({ config: false })
      })

    // unsubscribe to the listener when unmounting
    return () => {
      _.forEach([authUnsub, configPublicUnsub], (u) => u())
    }
  }, [auth])

  const updateClaims = async () => {
    const tokenResult = await auth.currentUser.getIdTokenResult(true)
    setClaims(tokenResult.claims)
  }

  return {
    ...FIREBASE_DEFAULTS,
    // when all flags in initStatus are false, then initializing == true
    initializing: _.some(_.values(initStatus)),
    currentUser: auth.currentUser,
    claims,
    updateClaims,
    configPublic,
    brand,
  }
}

export const useManager = () => {
  const { claims, db } = useContext(FirebaseContext)
  const [initStatus, setInitStatus] = useReducer(
    (state, data) => _.assign({}, state, data),
    { org: true, orgStaff: true }
  )

  const [organization, setOrganization] = useState(null)
  const [staffs, setStaffs] = useState([])

  const organizationId = _.get(claims, 'organizationId')

  useEffect(() => {
    if (!organizationId) {
      console.log('Orphan Manager!')
      return
    }

    const orgUnsub = db
      .doc(`organizations/${organizationId}`)
      .onSnapshot((doc) => {
        setOrganization(doc.data())
        setInitStatus({ org: false })
      })

    const orgStaffUnsub = db
      .collection(`organizations/${organizationId}/staffs`)
      .orderBy('name')
      .onSnapshot((snap) => {
        setStaffs(querySnapToObj(snap))
        setInitStatus({ orgStaff: false })
      })

    return () => {
      _.forEach([orgUnsub, orgStaffUnsub], (u) => u())
    }
  }, [organizationId, db])

  return {
    // when all flags in initStatus are false, then initializing == true
    initializing: _.some(_.values(initStatus)),
    organization,
    organizationId,
    staffs,
  }
}

export const usePublicSession = () => {
  const { db } = useContext(FirebaseContext)
  const { publicSessionId } = useParams()
  const [publicSession, setPublicSession] = useState(null)
  const [isReady, setIsReady] = useState(false)
  const [routes, setRoutes] = useState(null)

  useEffect(() => {
    const unsubscribe = db
      .doc(`publicSessions/${publicSessionId}`)
      .onSnapshot((snap) => {
        if (!snap.exists) {
          setIsReady(true)
          return
        }

        const publicSessionData = snap.data()

        // currentLocationUpdatedAt may not exist
        if (_.has(publicSessionData, 'currentLocationUpdatedAt')) {
          const currentLocationUpdatedAt = moment(
            publicSessionData.currentLocationUpdatedAt.toDate()
          )
          _.assign(publicSessionData, { currentLocationUpdatedAt })
        }

        setPublicSession(publicSessionData)
        setIsReady(true)
      })

    return () => unsubscribe()
  }, [db, publicSessionId])

  return {
    publicSession,
    publicSessionId,
    isReady,
    routes,
    setRoutes,
  }
}

export const useAdmin = () => {
  const { currentUser, claims, db, initializing } = useContext(FirebaseContext)
  const [organizations, setOrganizations] = useState({})

  useEffect(() => {
    if (!currentUser || !claims) {
      return () => {}
    }

    const unsub = db
      .collection('organizations')
      .orderBy('name')
      .onSnapshot((snap) => {
        setOrganizations(querySnapToObj(snap))
      })

    return () => unsub()
  }, [db, initializing, currentUser, claims])

  return { organizations }
}
