import _ from 'lodash'
import numeral from 'numeral'
import moment from 'moment'
import retry from 'async-retry'
import papa from 'papaparse'
import loadImage from 'blueimp-load-image'
import dataURLtoBlob from 'blueimp-canvas-to-blob'
import axios from 'axios'

export const querySnapToObj = (snap) =>
  _.transform(
    snap.docs,
    (result, doc) => {
      result[doc.id] = doc.data()
    },
    {}
  )

export const statusToStr = (status) =>
  ({
    DISPATCHED: '移動中',
    ARRIVED: '到着済み',
    DONE: '完了',
    CANCELLED: 'キャンセル済み',
  }[status])

export const calcRatio = (a, b) => {
  if (a === 0 || !_.isInteger(a)) return '0%'
  if (a + b === 0 || !_.isInteger(b)) return '100%'
  return numeral(a / (a + b)).format('0%')
}

export const uploadLogo = async ({ dataUrl, fileExt, basePath, storage }) => {
  if (!dataUrl) return

  // delete a thumb first, as overwriting the original causes some trouble
  const thumbRef = storage.ref(`${basePath}/logo_200x200${fileExt}`)
  try {
    await thumbRef.delete()
  } catch {}

  // upload original
  await storage.ref(`${basePath}/logo${fileExt}`).putString(dataUrl, 'data_url')

  // need to retry here because image-resising may take a bit of time
  const downloadUrl = await retry(async () => await thumbRef.getDownloadURL(), {
    factor: 1,
  })

  return downloadUrl
}

export const convertFileRefToDataUrl = ({ fileRef, setDataUrl, setFile }) => {
  if (!fileRef.current.files[0]) return
  const file = fileRef.current.files[0]
  const fileReader = new FileReader()
  loadImage.parseMetaData(file, (data) => {
    const options = {
      canvas: true,
    }
    if (data.exif) options.orientation = data.exif.get('Orientation')
    loadImage(
      file,
      (canvas) => {
        const imageUrl = canvas.toDataURL('image/jpeg')
        setDataUrl(imageUrl)
        fileReader.readAsDataURL(dataURLtoBlob(imageUrl))
      },
      options
    )
  })
  setFile(file)
}

export const downloadCsv = async ({ fileName, data, csvFields }) => {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf])
  const csvData = papa.unparse({ fields: _.values(csvFields), data })

  const blob = new Blob([bom, csvData], { type: 'text/plain' })

  if (window.navigator.msSaveBlob) {
    // IE,Edge
    window.navigator.msSaveBlob(blob, fileName)
  } else {
    // IE,Edge以外
    const objectURL = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    document.body.appendChild(link)
    link.href = objectURL
    link.download = fileName
    link.click()
    document.body.removeChild(link)
  }
}

export const formatTimestamp = (t) => {
  if (!t) return '-'

  const m = _.isString(t) || _.isDate(t) ? moment(t) : moment(t.toDate())
  return m.format('YYYY-MM-DD HH:mm')
}

export const isMobileBrowser = () => {
  return _.some([/android/i, /iPad|iPhone|iPod/i], (r) =>
    r.test(navigator.userAgent)
  )
}

export const isZipCode = (zip) => /^\d{7}$/.test(zip)

export const isNumber = (value) => /^\d*$/.test(value)

export const getAddrFromZip = async ({ zip, googleApiKey }) => {
  const addr = {
    pref: '',
    city: '',
    street: '',
  }

  try {
    const { data } = await axios.get(
      'https://maps.googleapis.com/maps/api/geocode/json',
      {
        params: { key: googleApiKey, address: zip, language: 'ja' },
      }
    )

    const { address_components } = data.results[0]

    if (address_components.length === 6) {
      addr.pref = address_components[4].long_name
      addr.city = `${address_components[3].long_name}${address_components[2].long_name}`
      addr.street = address_components[1].long_name
    }
    if (address_components.length === 5) {
      addr.pref = address_components[3].long_name
      addr.city = address_components[2].long_name
      addr.street = address_components[1].long_name
    }
  } catch (e) {}
  return addr
}
