import React, { useContext, Suspense, lazy } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import { FirebaseContext } from './lib/context'
import { useFirebase } from './lib/hook'

import LoadingScreen from './LoadingScreen'

const SessionScreen = lazy(() => import('./components/session/SessionScreen'))
const ManagerRoute = lazy(() => import('./components/manager/ManagerRoute'))
const ManagerScreen = lazy(() => import('./components/manager/ManagerScreen'))
const AdminRoute = lazy(() => import('./components/admin/AdminRoute'))
const AdminScreen = lazy(() => import('./components/admin/AdminScreen'))
const TesterTokenScreen = lazy(() => import('./TesterTokenScreen'))
const HomeScreen = lazy(() => import('./HomeScreen'))
const NotFound = lazy(() => import('./NotFound'))

// Wait until initialized
const FirebaseInitWrapper = ({ children }) => {
  const { initializing } = useContext(FirebaseContext)
  if (initializing) {
    return <LoadingScreen />
  }
  return children
}

const SignOut = () => {
  // Force sign-out
  const { auth } = useContext(FirebaseContext)
  auth.signOut()

  return <Redirect to='/' />
}

const Routes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          <Route exact path='/' component={HomeScreen} />
          <ManagerRoute path='/manager' component={ManagerScreen} />
          <AdminRoute path='/admin' component={AdminScreen} />
          <Route path='/_/:publicSessionId' component={SessionScreen} />
          <Route path='/testerToken/:uid' component={TesterTokenScreen} />
          <Route path='/signout' component={SignOut} />
          <Route path='*' component={NotFound} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}

export default () => {
  return (
    <FirebaseContext.Provider value={useFirebase()}>
      <FirebaseInitWrapper>
        <Routes />
      </FirebaseInitWrapper>
    </FirebaseContext.Provider>
  )
}
