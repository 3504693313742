const _ = require('lodash')

const baseTheme = {
  palette: {
    type: 'dark',
  },
  a: {
    textDecoration: 'none',
  },
}

const lobeamMuiTheme = {}

const dokovisitorMuiTheme = {
  palette: {
    primary: { main: '#a1001f' },
    secondary: { main: '#333E80' },
  },
}

export default {
  dokovisitor: {
    theme: _.merge({}, baseTheme, dokovisitorMuiTheme),
  },
  lobeam: {
    theme: _.merge({}, baseTheme, lobeamMuiTheme),
  },
}
