import React, { useContext } from 'react'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { CssBaseline } from '@material-ui/core'
import { FirebaseContext } from './lib/context'

export const DarkThemeProvider = ({ children }) => {
  const { brand } = useContext(FirebaseContext)

  if (!brand) return null

  return (
    <ThemeProvider theme={createMuiTheme(brand.theme)}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}
