import React from 'react'
import { LinearProgress } from '@material-ui/core'
import { DarkThemeProvider } from './Theme'

export default () => {
  return (
    <DarkThemeProvider>
      <LinearProgress />
    </DarkThemeProvider>
  )
}
